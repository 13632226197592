import { useState, useCallback, useMemo, RefObject } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';
import ReCAPTCHA from 'react-google-recaptcha';

import { useAuthContext } from '~/context/AuthContext';
import {
  RECAPTCHA_VALIDATION_MUTATION,
  RECAPTCHA_SCORE_THRESHOLD,
} from '~/constants/recaptcha';
import { LS_LOGIN_AFTER_CREATE } from '~/constants/localStorage';
import {
  MIN_FIELD_LENGTH,
  MAX_FIELD_LENGTH,
  passwordRegexp,
  latinAndSpaceRegexp,
  latinAndNumbersRegexp,
} from '~/constants/forms';

interface IChildrenProps {
  initialValues: any;
  handleCreateUser: (values: any, actions: any) => void;
  validationSchema: Yup.AnyObjectSchema;
  submitError: string;
  setSubmitError: React.Dispatch<React.SetStateAction<string>>;
}
interface ILoginFormHandler {
  children: (props: IChildrenProps) => JSX.Element;
  recaptchaRef: RefObject<ReCAPTCHA>;
}

const CREATE_USER_MUTATION = gql`
  mutation createUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput)
  }
`;

const initialValues = {
  login: '',
  password: '',
  passwordAgain: '',
  firstName: '',
  name: '',
  nickname: '',
  country: '',
};

const CreateAccFormHandler = ({
  children,
  recaptchaRef,
}: ILoginFormHandler): JSX.Element => {
  const { setAuthenticated } = useAuthContext();
  const [submitError, setSubmitError] = useState('');

  const [validateCaptcha] = useMutation(RECAPTCHA_VALIDATION_MUTATION);
  const [updateCreateUser] = useMutation(CREATE_USER_MUTATION);

  const handleCreateUser = useCallback(
    async (values, { setSubmitting, setFieldError }) => {
      const captchaToken = await recaptchaRef.current?.executeAsync();
      recaptchaRef.current?.reset();

      if (values.password === values.passwordAgain) {
        validateCaptcha({
          variables: {
            token: captchaToken,
          },
        }).then(({ data }: any) => {
          if (
            data?.captcha.success &&
            data.captcha.score >= RECAPTCHA_SCORE_THRESHOLD
          ) {
            updateCreateUser({
              variables: {
                createUserInput: {
                  email: values.login,
                  password: values.password,
                  firstName: values.firstName,
                  lastName: values.name,
                  nickname: values.nickname,
                  country: values.country,
                },
              },
            })
              .then(() => {
                localStorage.setItem(LS_LOGIN_AFTER_CREATE, 'true');
                setSubmitting(false);
                setAuthenticated(true);
                console.log('Congrats! New user created.');
              })
              .catch((error) => {
                setSubmitting(false);
                setSubmitError(error);

                error.graphQLErrors?.forEach((err: any) => {
                  err.extensions?.response?.message?.forEach((message: any) => {
                    if (
                      message.property === 'email' &&
                      message.constraints?.unique ===
                        'error.fields.email_unique'
                    ) {
                      setFieldError('login', t('error.fields.email_unique'));
                    }
                    if (
                      message.property === 'nickname' &&
                      message.constraints?.unique ===
                        'error.fields.nickname_unique'
                    ) {
                      setFieldError(
                        'nickname',
                        t('error.fields.nickname_unique')
                      );
                    }
                  });
                });
              });
          } else {
            setSubmitting(false);
            setSubmitError(t('generic_error'));
          }
        });
      } else {
        setSubmitError(t('form_password-mach'));
      }

      setSubmitting(false);
    },
    []
  );

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        login: Yup.string()
          .email(t('form_email-validation'))
          .required(t('form_required-field')),
        password: Yup.string()
          .required(t('form_required-field'))
          .matches(passwordRegexp, t('form_password-validation')),
        passwordAgain: Yup.string()
          .required(t('form_required-field'))
          .matches(passwordRegexp, t('form_password-validation')),
        firstName: Yup.string()
          .max(MAX_FIELD_LENGTH, t('form_max-symbols'))
          .matches(latinAndSpaceRegexp, t('form_latin-spaces-regex'))
          .required(t('form_required-field')),
        name: Yup.string()
          .max(MAX_FIELD_LENGTH, t('form_max-symbols'))
          .matches(latinAndSpaceRegexp, t('form_latin-spaces-regex'))
          .required(t('form_required-field')),
        nickname: Yup.string()
          .min(MIN_FIELD_LENGTH, t('form_min-symbols'))
          .max(MAX_FIELD_LENGTH, t('form_max-symbols'))
          .matches(latinAndNumbersRegexp, t('form_latin-numbers-regex'))
          .required(t('form_required-field')),
        country: Yup.string().required(t('form_required-field')),
      }),
    [t]
  );

  return children({
    initialValues,
    handleCreateUser,
    validationSchema,
    submitError,
    setSubmitError,
  });
};

export default CreateAccFormHandler;
