import { useState, useCallback, useMemo, RefObject } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';
import ReCAPTCHA from 'react-google-recaptcha';

import { useAuthContext } from '~/context/AuthContext';
import {
  RECAPTCHA_VALIDATION_MUTATION,
  RECAPTCHA_SCORE_THRESHOLD,
} from '~/constants/recaptcha';

interface IChildrenProps {
  initialValues: any;
  isAuthenticated: boolean;
  handleAuthorize: (values: any, actions: any) => void;
  validationSchema: Yup.AnyObjectSchema;
  submitError: string;
  setSubmitError: React.Dispatch<React.SetStateAction<string>>;
}
interface ILoginFormHandler {
  children: (props: IChildrenProps) => JSX.Element;
  recaptchaRef: RefObject<ReCAPTCHA>;
}

const LOGIN_USER_MUTATION = gql`
  mutation userLogin($loginUserInput: LoginUserInput!) {
    userLogin(loginUserInput: $loginUserInput)
  }
`;

const initialValues = { login: '', password: '' };

const LoginFormHandler = ({
  children,
  recaptchaRef,
}: ILoginFormHandler): JSX.Element => {
  const { isAuthenticated, setAuthenticated } = useAuthContext();
  const [submitError, setSubmitError] = useState('');

  const [validateCaptcha] = useMutation(RECAPTCHA_VALIDATION_MUTATION);
  const [updateLogin] = useMutation(LOGIN_USER_MUTATION);

  const handleAuthorize = useCallback(async (values, { setSubmitting }) => {
    const captchaToken = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();

    validateCaptcha({
      variables: {
        token: captchaToken,
      },
    }).then(({ data }: any) => {
      if (data?.captcha.success && data.captcha.score >= RECAPTCHA_SCORE_THRESHOLD) {
        updateLogin({
          variables: {
            loginUserInput: {
              email: values.login,
              password: values.password,
            },
          },
        })
          .then(() => {
            setSubmitting(false);
            setAuthenticated(true);

            console.log("Congrats! You're authorized now");
          })
          .catch((error) => {
            console.warn(error);
            setSubmitting(false);
            setSubmitError(t('login_submit-error'));
          });
      } else {
        setSubmitting(false);
        setSubmitError(t('login_submit-error'));
      }
    });
  }, []);

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        login: Yup.string()
          .email(t('form_email-validation'))
          .required(t('form_required-field')),
        password: Yup.string().required(t('form_required-field')),
      }),
    []
  );

  return children({
    initialValues,
    isAuthenticated,
    handleAuthorize,
    validationSchema,
    submitError,
    setSubmitError,
  });
};

export default LoginFormHandler;
