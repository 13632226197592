import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import moment from 'moment';

import { useAuthContext } from '~/context/AuthContext';
import logoImage from '~/media/img/HeroLogoWhite.png';

import styles from './NotOnAirPage.module.scss';

const cx = classNames.bind(styles);

const NotOnAirPage = (): JSX.Element => {
  const { t } = useTranslation();

  const { isCountdownActive, isOutcomeActive, countdownTime } =
    useAuthContext();

  const [countdownDiff, setCountdownDiff] = useState(
    moment.duration(moment(countdownTime).diff(moment()))
  );

  useEffect(() => {
    if (isCountdownActive) {
      const interval = setInterval(() => {
        setCountdownDiff(moment.duration(moment(countdownTime).diff(moment())));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  const outcomeText = useMemo(() => {
    if (!t('outcome-text').includes('@outcomeLinkLabel'))
      return <span>{t('outcome-text')}</span>;

    const [fromText, perText] = t('outcome-text').split('@outcomeLinkLabel');

    return (
      <span>
        {fromText}
        <a className={cx('outcome-link')} href={t('outcome-link-link')}>
          {t('outcome-link-label')}
        </a>
        {perText}
      </span>
    );
  }, [t]);

  const timerText = useMemo(() => {
    const hours = `${
      String(countdownDiff.hours()).length === 1 ? '0' : ''
    }${countdownDiff.hours()}`;
    const minutes = `${
      String(countdownDiff.minutes()).length === 1 ? '0' : ''
    }${countdownDiff.minutes()}`;
    const seconds = `${
      String(countdownDiff.seconds()).length === 1 ? '0' : ''
    }${countdownDiff.seconds()}`;

    const time = `${hours}:${minutes}:${seconds}`;
    const days =
      countdownDiff.days() > 0
        ? `${countdownDiff.days()} day${
            countdownDiff.days() === 1 ? ' ' : 's '
          }`
        : '';

    return `${days}${time}`
      .split('')
      .map((c) => `<span>${c}</span>`)
      .join('');
  }, [countdownDiff]);

  if (
    isCountdownActive &&
    !isOutcomeActive &&
    countdownDiff.as('milliseconds') < 0
  ) {
    window.location.reload();
  }

  return (
    <main className={cx('not-on-air-page')}>
      <header className={cx('header')}>
        <a
          className={cx('havana-link')}
          href={'https://havana-club.com/en/'}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <img src={logoImage} alt="Havana Club" />
        </a>
      </header>
      <div className={cx('text')}>
        {isCountdownActive ? t('countdown-text') : outcomeText}
      </div>
      {isCountdownActive && (
        <div className={cx('timer')}>
          <div dangerouslySetInnerHTML={{ __html: timerText }} />
        </div>
      )}
    </main>
  );
};

export default NotOnAirPage;
