import classNames from 'classnames/bind';
import { FieldProps } from 'formik';

import styles from './Select.module.scss';

interface IOption {
  name: string;
  id: string;
}

interface IInputProps {
  placeholder?: string;
  options: IOption[];
}

const cx = classNames.bind(styles);

const Select = ({
  placeholder,
  options,
  field,
}: IInputProps & FieldProps): JSX.Element => {
  return (
    <select {...field} className={cx('select')}>
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map(({ name, id }, index) => (
        <option value={id} key={index}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default Select;
