import classNames from 'classnames/bind';

import styles from './Loader.module.scss';

const cx = classNames.bind(styles);

interface ILoader {
  className?: string;
}

const Loader = ({ className }: ILoader): JSX.Element => {
  return <div className={cx('loader', className)} />;
};

export default Loader;
