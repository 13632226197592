import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import styles from './Header.module.scss';

import { useAuthContext } from '~/context/AuthContext';
import logoImage from '~/media/img/LogoHavanaClubQuest.png';

const USER_LOGOUT_MUTATION = gql`
  mutation userLogout {
    userLogout
  }
`;

const cx = classNames.bind(styles);

const Header = (): JSX.Element => {
  const history = useHistory();
  const { isAuthenticated, isLocalAuth, name, setAuthenticated } =
    useAuthContext();
  const [logout, { data: logoutData }] = useMutation(USER_LOGOUT_MUTATION);

  const handleLogout = useCallback(() => {
    logout();
  }, []);

  useEffect(() => {
    if (logoutData) {
      setAuthenticated(false);
    }
  }, [logoutData]);

  return (
    <header className={cx('header')}>
      <div className={cx('logo')}>
        <Link to={'/'}>
          <img src={logoImage} alt="Havana Club QUEST" />
        </Link>
      </div>
      {isAuthenticated ? (
        <div className={cx('user-menu')}>
          <div className={cx('name')}>
            <Link to={'/profile'}>{name}</Link>
          </div>
          {isLocalAuth && (
            <button
              className={cx('button-image', 'disconnect-button')}
              type="button"
              onClick={handleLogout}
            >
              Disconnect
            </button>
          )}
        </div>
      ) : (
        <div className={cx('user-menu')}>
          <button
            className={cx('button-image', 'connect-button')}
            type="button"
            onClick={() => {
              history.push('/login');
            }}
          >
            Login
          </button>
          <button
            className={cx('button-image', 'register-button')}
            type="button"
            onClick={() => {
              history.push('/signup');
            }}
          >
            Register
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
