import { createContext, useContext, useMemo, useState, useEffect } from 'react';

import debounce from '~/utils/debounce';

interface IUiContextInterface {
  isDesktop: boolean;
}

const DESKTOP_WIDTH = 900;

const getPixelRatio = () => {
  if (window.devicePixelRatio > 2) {
    return window.devicePixelRatio / 2;
  }
  if (window.devicePixelRatio > 1 && window.devicePixelRatio < 2) {
    return window.devicePixelRatio;
  }
  return 1;
};

let devicePixelRatio = getPixelRatio();

const UiContext = createContext<IUiContextInterface>({
  isDesktop: window.innerWidth * devicePixelRatio >= DESKTOP_WIDTH,
});

const useUiContext = (): IUiContextInterface => useContext(UiContext);

const UiContextProvider = (
  props: React.PropsWithChildren<Record<string, unknown>>
): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = debounce(150, () => {
      setWidth(window.innerWidth);
    });

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isDesktop = useMemo(() => {
    devicePixelRatio = getPixelRatio();

    return width * devicePixelRatio >= DESKTOP_WIDTH;
  }, [width]);

  return <UiContext.Provider value={{ isDesktop }} {...props} />;
};

const UiContextConsumer = UiContext.Consumer;

export { useUiContext, UiContextProvider, UiContextConsumer };
export default UiContext;
