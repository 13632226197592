import classNames from 'classnames/bind';

import styles from './InfoPage.module.scss';

interface IInfoPage {
  text?: string;
  children?: React.ReactNode;
}

const cx = classNames.bind(styles);

const InfoPage = ({ text, children }: IInfoPage): JSX.Element => {
  return (
    <div className={cx('info-page')}>
      {children && <div className={cx('info-text')}>{children}</div>}
      {text && (
        <div
          className={cx('info-text')}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  );
};

export default InfoPage;
