import LoginPage from '~/pages/LoginPage';
import CreateAccPage from '~/pages/CreateAccPage';
import ForgotPassPage from '~/pages/ForgotPassPage';
import MobileInfoPage from '~/pages/MobileInfoPage';
import HomePage from '~/pages/HomePage';
import GamePage from '~/pages/GamePage';
import EditProfilePage from '~/pages/EditProfilePage';
import LeaderbordPage from '~/pages/LeaderbordPage';
import FirstLoginInfoPage from '~/pages/FirstLoginInfoPage';

const AUTH_ROUTES = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/signup',
    component: CreateAccPage,
  },
  {
    path: '/forgot-password',
    component: ForgotPassPage,
  },
  {
    path: '/mobile',
    component: MobileInfoPage,
  },
];

const HCGAME_ROUTES = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/hc-quest',
    component: GamePage,
  },
  {
    path: '/profile',
    component: EditProfilePage,
  },
  {
    path: '/leaderboard',
    component: LeaderbordPage,
  },
  {
    path: '/mobile',
    component: MobileInfoPage,
  },
  {
    path: '/signup/congratulations',
    component: FirstLoginInfoPage,
  },
];

export { AUTH_ROUTES, HCGAME_ROUTES };
