import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import { useAuthContext } from '~/context/AuthContext';

import styles from './HomePage.module.scss';

const cx = classNames.bind(styles);

const HomePage = (): JSX.Element => {
  const { isAuthenticated } = useAuthContext();

  return (
    <div className={cx('homepage')}>
      <Link
        to={isAuthenticated ? '/hc-quest' : '/login'}
        className={cx('link-game')}
      >
        Game
      </Link>
      <Link
        to={isAuthenticated ? '/leaderboard' : '/login'}
        className={cx('link-leaderboard')}
      >
        Leaderboard
      </Link>
    </div>
  );
};

export default HomePage;
