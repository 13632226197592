import InfoPage from '~/pages/InfoPage';
import { useTranslation } from 'react-i18next';

const MobileInfoPage = (): JSX.Element => {
  const { t } = useTranslation();

  return <InfoPage text={t('infoPage_mobile-message')} />;
};

export default MobileInfoPage;
