import { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames/bind';
import ReCAPTCHA from 'react-google-recaptcha';

import Input from '~/components/FormComponents/Input';
import FieldError from '~/components/FormComponents/FieldError';
import SubmitError from '~/components/FormComponents/SubmitError';
import SubmitSuccess from '~/components/FormComponents/SubmitSuccess';

import ForgotPassFormHandler from './containers/ForgotPassFormHandler';
import styles from './ForgotPassForm.module.scss';

const { REACT_APP_RECAPTCHA_KEY = '' } = process.env;

const cx = classNames.bind(styles);

const ForgotPassForm = (): JSX.Element => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  
  return (
    <div className={cx('login-form')}>
      <ForgotPassFormHandler recaptchaRef={recaptchaRef}>
        {({
          initialValues,
          validationSchema,
          handleCreateUser,
          submitErrorMessage,
          setSubmitErrorMessage,
          submitSuccessMessage,
          setSubmitSuccessMessage,
        }) => {
          return (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleCreateUser}
            >
              {(formikProps) => {
                const { isSubmitting, isValid } = formikProps;

                return (
                  <Form
                    onChange={() => {
                      setSubmitErrorMessage('');
                      setSubmitSuccessMessage('');
                    }}
                  >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={REACT_APP_RECAPTCHA_KEY}
                    />
                    <Field name="login" placeholder="Email" component={Input} />
                    <FieldError name="login" />

                    <SubmitError message={submitErrorMessage} />
                    <SubmitSuccess message={submitSuccessMessage} />
                    <div className={cx('cta-wrapper')}>
                      <button
                        className={cx('submit-button')}
                        type="submit"
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          submitErrorMessage.length > 0 ||
                          submitSuccessMessage.length > 0
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </ForgotPassFormHandler>
    </div>
  );
};

export default ForgotPassForm;
