import { useEffect } from 'react';

interface IScrollToTopOnLocationChange {
  location: {
    [key: string]: string;
  };
}

function ScrollToTopOnLocationChange({
  location: { pathname },
}: IScrollToTopOnLocationChange): null {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTopOnLocationChange;
