import { createContext, useContext, useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { useLazyQuery, gql, useApolloClient } from '@apollo/client';
import moment from 'moment';

import { JWT_COOKIE } from '~/constants/cookies';
import usePrevious from '~/hooks/usePrevious';
import { EVENT_TYPE_GLOBAL } from '~/constants/common';

import {
  IAuthContextInterface,
  IJWT,
  ICurrentEvent,
} from './AuthContext.types';

const initialUserData = {
  email: '',
  firstName: '',
  lastName: '',
  nickname: '',
  country: '',
};

const AuthContext = createContext<IAuthContextInterface>({
  isAuthenticated: true,
  name: null,
  email: null,
  setAuthenticated: () => undefined,
  userData: initialUserData,
  eventData: null,
  isEventActive: false,
  isLocalAuth: false,
  isCountdownActive: false,
  countdownTime: null,
  isOutcomeActive: false,
  setUserData: () => undefined,
  refetchUserData: null,
});

const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      email
      firstName
      lastName
      nickname
      country
    }
    currentEvent {
      name
      description
      startDate
      endDate
      prizes
      type
    }
  }
`;

const useAuthContext = (): IAuthContextInterface => useContext(AuthContext);

const AuthContextProvider = (
  props: React.PropsWithChildren<Record<string, unknown>>
): JSX.Element => {
  const client = useApolloClient();

  const [getCurrentUser, { data: currentUserData, refetch: refetchUserData }] =
    useLazyQuery(CURRENT_USER_QUERY);

  const [userData, setUserData] = useState(initialUserData);
  const [eventData, setEventData] = useState<ICurrentEvent | null>(null);
  const [isEventActive, setEventActive] = useState<boolean>(false);
  const [isLocalAuth, setLocalAuth] = useState<boolean>(false);
  const [isCountdownActive, setCountdownActive] = useState<boolean>(false);
  const [countdownTime, setCountdownTime] = useState<string | null>(null);
  const [isOutcomeActive, setOutcomeActive] = useState<boolean>(false);

  const [authToken, setAuthToken] = useState<string | undefined>(
    Cookie.get(JWT_COOKIE)
  );

  const [name, setName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();

  const [isAuthenticated, setAuthenticated] = useState<boolean>(
    Boolean(authToken)
  );
  const prevAuthenticated = usePrevious(isAuthenticated);

  useEffect(() => {
    if (!prevAuthenticated && isAuthenticated && currentUserData) {
      client.clearStore().then(() => {
        client.resetStore();
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!currentUserData) {
        getCurrentUser();
      } else {
        setUserData(currentUserData.currentUser);
        setEventData(currentUserData.currentEvent);
        setName(currentUserData.currentUser.nickname);
      }
    }
  }, [isAuthenticated, currentUserData]);

  useEffect(() => {
    const jwtCookie = Cookie.get(JWT_COOKIE);

    if (jwtCookie) {
      const cookie = jwt_decode<IJWT>(jwtCookie);
      setAuthenticated(true);
      setAuthToken(jwtCookie);
      setName(cookie.nickname);
      setEmail(cookie.email);
      setLocalAuth(cookie.authMethod === 'local' || cookie.authMethod === '');
    } else {
      setAuthenticated(false);
      setAuthToken(undefined);
      setName(undefined);
    }
  }, [authToken, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      setEventData(null);
    } else {
      if (eventData) {
        setEventActive(
          moment().isBetween(eventData.startDate, eventData.endDate)
        );
      }

      if (eventData?.type === EVENT_TYPE_GLOBAL) {
        setCountdownActive(moment().isBefore(eventData.startDate));
        setOutcomeActive(moment().isAfter(eventData.endDate));
        setCountdownTime(eventData.startDate);
      }
    }
  }, [eventData, isAuthenticated]);

  useEffect(() => {
    if (
      eventData?.type === EVENT_TYPE_GLOBAL &&
      !isOutcomeActive &&
      moment().isBetween(eventData.startDate, eventData.endDate)
    ) {
      const interval = setInterval(() => {
        if (moment().isAfter(eventData.endDate)) {
          setOutcomeActive(true);
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [eventData]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        name,
        email,
        setAuthenticated,
        userData,
        eventData,
        isEventActive,
        isLocalAuth,
        isCountdownActive,
        countdownTime,
        isOutcomeActive,
        setUserData,
        refetchUserData,
      }}
      {...props}
    />
  );
};

const AuthContextConsumer = AuthContext.Consumer;

export { useAuthContext, AuthContextProvider, AuthContextConsumer };
export default AuthContext;
