import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const { REACT_APP_GRAPHQL_ENDPOINT } = process.env;

const httpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...(process.env.REACT_APP_GRAPHQL_HTTP_AUTH && {
        authorization: process.env.REACT_APP_GRAPHQL_HTTP_AUTH,
      }),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
