import { ErrorMessage } from 'formik';
import classNames from 'classnames/bind';

import styles from './FieldError.module.scss';

interface IFieldError {
  name: string;
}

const cx = classNames.bind(styles);

const FieldError = ({ name }: IFieldError): JSX.Element => {
  return (
    <ErrorMessage name={name}>
      {(msg) => <div className={cx('field-error')}>{msg}</div>}
    </ErrorMessage>
  );
};

export default FieldError;
