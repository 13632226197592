import { useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import MobileInfoPage from '~/pages/MobileInfoPage';
import ScrollToTopOnLocationChange from '~/components/ScrollToTopOnLocationChange';

import { useUiContext } from '~/context/UiContext';
import { useAuthContext } from '~/context/AuthContext';
import { LS_LOGIN_AFTER_CREATE } from '~/constants/localStorage';
import { AUTH_ROUTES, HCGAME_ROUTES } from '~/constants/routes';

const { REACT_APP_LOGIN_FORCE_SSO, REACT_APP_GRAPHQL_ENDPOINT } = process.env;

const NoMatch = () => <Redirect to="/" />;

const Routes = (): JSX.Element => {
  const { isDesktop } = useUiContext();
  const { isAuthenticated } = useAuthContext();

  const routesList = useMemo(
    () => (isAuthenticated ? HCGAME_ROUTES : AUTH_ROUTES),
    [isAuthenticated]
  );

  if (!isAuthenticated && REACT_APP_LOGIN_FORCE_SSO === 'true') {
    window.location.href = `${REACT_APP_GRAPHQL_ENDPOINT}/auth/sso`
  }

  if (!isDesktop) {
    return <Route component={MobileInfoPage} />;
  }

  return (
    <>
      <Route component={ScrollToTopOnLocationChange} />
      <Switch>
        {routesList.map((listProps, index) => (
          <Route key={index} exact {...listProps} />
        ))}
        <Route component={NoMatch} />
      </Switch>
      {isAuthenticated && localStorage.getItem(LS_LOGIN_AFTER_CREATE) && (
        <Redirect to="/signup/congratulations" />
      )}
    </>
  );
};

export default Routes;
