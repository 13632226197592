import { useEffect, useState, useMemo, useRef, useLayoutEffect } from 'react';
import Unity, { UnityContent } from 'react-unity-webgl';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';

import ContentContainer from '~/components/ContentContainer';
import { useAuthContext } from '~/context/AuthContext';
import Loader from '~/components/Loader';
import debounce from '~/utils/debounce';

import styles from './GamePage.module.scss';

const SEND_SCORE_MUTATION = gql`
  mutation inputScore($payload: String!) {
    inputScore(payload: $payload)
  }
`;

const cx = classNames.bind(styles);

const { PUBLIC_URL, REACT_APP_GAME_META_FILE = 'build.json' } = process.env;

const unityContent = new UnityContent(
  `${PUBLIC_URL}/hcgame/Build/${REACT_APP_GAME_META_FILE}`,
  `${PUBLIC_URL}/hcgame/Build/UnityLoader.js`
);

const PROPORTION = 56.35;
const GAME_WIDTH_DEFAULT = 1150;
const GAME_SIDE_GAP = 490; //(100vw - 245px - 245px)
const GAME_TOP_GAP = 240; // header height + gap

const GamePage = (): JSX.Element => {
  const { t } = useTranslation();
  const { email, eventData, isEventActive } = useAuthContext();

  const gameRef = useRef<HTMLInputElement>(null);
  const [gameHeight, setGameHeight] = useState<number | null>(null);
  const [gameWidth, setGameWidth] = useState<number | null>(null);

  const setGameSize = () => {
    if (gameRef?.current) {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const maxWidth =
        windowWidth - GAME_SIDE_GAP > GAME_WIDTH_DEFAULT
          ? GAME_WIDTH_DEFAULT
          : windowWidth - GAME_SIDE_GAP;
      const maxHeightByWidth = Math.round((maxWidth * PROPORTION) / 100);
      const maxHeight = Math.min(maxHeightByWidth, windowHeight - GAME_TOP_GAP);
      const maxWidthByHeight = Math.round((maxHeight * 100) / PROPORTION);

      setGameWidth(Math.min(maxWidthByHeight, maxWidth));
      setGameHeight(maxHeight);
    }
  };

  useEffect(() => {
    const handleWindowResize = debounce(150, setGameSize);

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useLayoutEffect(setGameSize, []);

  const goalLabels = useMemo(() => {
    if (isEventActive) {
      return eventData?.prizes?.slice(0, 3);
    }

    return [t('game_goal1'), t('game_goal2'), t('game_goal3')];
  }, [eventData, isEventActive]);

  const [progression, setProgression] = useState(0);

  const [submitScores] = useMutation(SEND_SCORE_MUTATION);

  useEffect(() => {
    if (email && unityContent) {
      // game loading callback: from 0 to 1
      unityContent.on('progress', function (progression: number) {
        setProgression(progression);

        if (progression >= 1) {
          unityContent.send('PlayerStats', 'SetPlayerId', email);
        }
      });

      unityContent.on('GameOver', function (score: string) {
        submitScores({ variables: { payload: score } })
          .then(() => {
            console.log('Score submitted.');
          })
          .catch((error) => {
            console.warn(error);
          });
      });
    }
  }, [email, unityContent]);

  return (
    <ContentContainer className={cx('game-page')}>
      <div className={cx('left-bar')}>
        <div>
          {goalLabels?.map((goalLabel, index) => (
            <div className={cx('score')} key={index}>
              {goalLabel}
            </div>
          ))}
        </div>
        <Link className={cx('score-link')} to={'/leaderboard'}>
          Event leaderboard
        </Link>
      </div>
      <div
        className={cx('game-wrapper')}
        style={{
          ...(gameWidth && { width: gameWidth }),
          ...(gameHeight && { height: gameHeight }),
        }}
      >
        {progression < 1 && <Loader className={cx('game-loader')} />}
        <div
          ref={gameRef}
          style={{
            maxWidth: window.innerWidth - GAME_SIDE_GAP,
            ...(gameWidth
              ? { width: gameWidth }
              : { width: GAME_WIDTH_DEFAULT }),
            ...(gameHeight && { height: gameHeight }),
          }}
        >
          <Unity unityContent={unityContent} />
        </div>
      </div>
      <div className={cx('controls')}>
        <div className={cx('control', 'jump')}>Z or UP to jump</div>
        <div className={cx('control', 'slide')}>S or DOWN to slide</div>
        <div className={cx('control', 'attack')}>SPACE to attack</div>
      </div>
    </ContentContainer>
  );
};

export default GamePage;
