import { useState, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';

import { useAuthContext } from '~/context/AuthContext';
import {
  MIN_FIELD_LENGTH,
  MAX_FIELD_LENGTH,
  passwordRegexp,
  latinAndSpaceRegexp,
  latinAndNumbersRegexp,
} from '~/constants/forms';

interface IChildrenProps {
  initialValues: any;
  handleEditUser: (values: any, actions: any) => void;
  validationSchema: Yup.AnyObjectSchema;
  submitErrorMessage: string;
  setSubmitErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  submitSuccessMessage: string;
  setSubmitSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}
interface IEditProfileFormHandler {
  children: (props: IChildrenProps) => JSX.Element;
}

const EDIT_USER_MUTATION = gql`
  mutation editUser($editUserInput: EditUserInput!) {
    editUser(editUserInput: $editUserInput) {
      email
      firstName
      lastName
      nickname
      country
    }
  }
`;

const EditProfileFormHandler = ({
  children,
}: IEditProfileFormHandler): JSX.Element => {
  const { userData, refetchUserData, isLocalAuth } = useAuthContext();
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState('');

  const initialValues = {
    login: userData.email || '',
    ...(isLocalAuth && { password: '', passwordAgain: '' }),
    firstName: userData.firstName || '',
    name: userData.lastName || '',
    nickname: userData.nickname || '',
    country: userData.country || '',
  };

  const [updateUser] = useMutation(EDIT_USER_MUTATION);

  const handleEditUser = useCallback(
    async (values, { setSubmitting, setFieldError }) => {
      if (values.password === values.passwordAgain || isLocalAuth) {
        updateUser({
          variables: {
            editUserInput: {
              ...(isLocalAuth && {
                password: values.password || undefined,
              }),
              firstName: values.firstName,
              lastName: values.name,
              nickname: values.nickname,
              country: values.country,
            },
          },
        })
          .then((data) => {
            setSubmitting(false);
            // @todo: implement userData refresh
            console.log(data);
            refetchUserData();

            setSubmitSuccessMessage(t('editProfile_submit-success'));
            console.log('Congrats! Profile updated.');
          })
          .catch((error) => {
            setSubmitting(false);
            setSubmitErrorMessage(error);

            error.graphQLErrors?.forEach((err: any) => {
              err.extensions?.response?.message?.forEach((message: any) => {
                if (
                  message.property === 'nickname' &&
                  message.constraints?.unique === 'error.fields.nickname_unique'
                ) {
                  setFieldError('nickname', t('error.fields.nickname_unique'));
                }
              });
            });
          });
      } else {
        setSubmitErrorMessage(t('form_password-mach'));
      }

      setSubmitting(false);
    },
    []
  );

  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        login: Yup.string()
          .email(t('form_email-validation'))
          .required(t('form_required-field')),
        ...(isLocalAuth && {
          password: Yup.string().matches(
            passwordRegexp,
            t('form_password-validation')
          ),
          passwordAgain: Yup.string().matches(
            passwordRegexp,
            t('form_password-validation')
          ),
        }),
        firstName: Yup.string()
          .max(MAX_FIELD_LENGTH, t('form_max-symbols'))
          .matches(latinAndSpaceRegexp, t('form_latin-spaces-regex'))
          .required(t('form_required-field')),
        name: Yup.string()
          .max(MAX_FIELD_LENGTH, t('form_max-symbols'))
          .matches(latinAndSpaceRegexp, t('form_latin-spaces-regex'))
          .required(t('form_required-field')),
        nickname: Yup.string()
          .min(MIN_FIELD_LENGTH, t('form_min-symbols'))
          .max(MAX_FIELD_LENGTH, t('form_max-symbols'))
          .matches(latinAndNumbersRegexp, t('form_latin-numbers-regex'))
          .required(t('form_required-field')),
        country: Yup.string().required(t('form_required-field')),
      }),
    [t]
  );

  return children({
    initialValues,
    handleEditUser,
    validationSchema,
    submitErrorMessage,
    setSubmitErrorMessage,
    submitSuccessMessage,
    setSubmitSuccessMessage,
  });
};

export default EditProfileFormHandler;
