import { Fragment, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import styles from './Footer.module.scss';

const { REACT_APP_LOGIN_FORCE_SSO } = process.env;

const cx = classNames.bind(styles);

interface IFooter {
  isEvent: boolean;
}

const Footer = ({ isEvent }: IFooter): JSX.Element => {
  const history = useHistory();

  const [isHomepage, setIsHomepage] = useState(
    history.location.pathname === '/'
  );

  const { t } = useTranslation();

  const links = useMemo(
    () => [
      {
        label: t('footer_link1-label'),
        url:
          REACT_APP_LOGIN_FORCE_SSO === 'true'
            ? t('footer_link1-sso-link')
            : t('footer_link1-link'),
      },
      {
        label: t('footer_link2-label'),
        url:
          REACT_APP_LOGIN_FORCE_SSO === 'true'
            ? t('footer_link2-sso-link')
            : t('footer_link2-link'),
      },
      { label: t('footer_link3-label'), url: t('footer_link3-link') },
      { label: t('footer_link4-label'), url: t('footer_link4-link') },
      { label: t('footer_link5-label'), url: t('footer_link5-link') },
      { label: t('footer_link6-label'), url: t('footer_link6-link') },
    ],
    [t]
  );

  history.listen((location) => {
    setIsHomepage(location.pathname === '/');
  });

  return (
    <footer className={cx('footer')}>
      {!isHomepage && (
        <div className={cx('bottom-links')}>
          <Link to={'/'} className={cx('bottom-link')}>
            <span>Homepage</span>
          </Link>
        </div>
      )}
      <div className={cx('logo', { event: isEvent })} />
      <div className={cx('footer-menu')}>
        {links.map(({ label, url }, index) => {
          return (
            <Fragment key={index}>
              {index !== 0 && '–'}
              <a
                className={cx('menu-link')}
                href={url}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                {label}
              </a>
            </Fragment>
          );
        })}
      </div>
    </footer>
  );
};

export default Footer;
