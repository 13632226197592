import { Router } from 'react-router-dom';

import { AuthContextProvider } from '~/context/AuthContext';
import { UiContextProvider } from '~/context/UiContext';
import Routes from '~/components/Routes';
import PageContainer from '~/components/PageContainer';
import history from '~/utils/history';

function App(): JSX.Element {
  return (
    <AuthContextProvider>
      <UiContextProvider>
        <Router history={history}>
          <PageContainer>
            <Routes />
          </PageContainer>
        </Router>
      </UiContextProvider>
    </AuthContextProvider>
  );
}

export default App;
