import classNames from 'classnames/bind';
import { FieldProps } from 'formik';

import styles from './Input.module.scss';

interface IInputProps {
  placeholder?: string;
  type?: string;
  maxLength?: number;
  disabled?: boolean;
}

const cx = classNames.bind(styles);

const Input = ({
  placeholder,
  type = 'text',
  field,
  form,
  maxLength = 255,
  disabled,
}: IInputProps & FieldProps): JSX.Element => {
  const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || '';

    if (
      type === 'text' &&
      value.length > 0 &&
      (value[0] === ' ' || value[value.length - 1] === ' ')
    ) {
      form.setFieldValue(field.name, value.trim() || '');
    }

    field.onBlur(e);
  };

  return (
    <input
      {...field}
      type={type}
      className={cx('input')}
      placeholder={placeholder}
      maxLength={maxLength}
      onBlur={blurHandler}
      disabled={disabled}
    />
  );
};

export default Input;
