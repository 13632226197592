import { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames/bind';

import Input from '~/components/FormComponents/Input';
import FieldError from '~/components/FormComponents/FieldError';
import SubmitError from '~/components/FormComponents/SubmitError';
import ReCAPTCHA from 'react-google-recaptcha';

import LoginFormHandler from './containers/LoginFormHandler';
import styles from './LoginForm.module.scss';

const { REACT_APP_RECAPTCHA_KEY = '' } = process.env;

const cx = classNames.bind(styles);

const LoginForm = (): JSX.Element => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  return (
    <div className={cx('login-form')}>
      <LoginFormHandler recaptchaRef={recaptchaRef}>
        {({
          initialValues,
          isAuthenticated,
          handleAuthorize,
          validationSchema,
          submitError,
          setSubmitError,
        }) => (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleAuthorize}
          >
            {(formikProps) => {
              const { isSubmitting, isValid } = formikProps;

              return (
                <Form
                  onChange={() => {
                    setSubmitError('');
                  }}
                >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={REACT_APP_RECAPTCHA_KEY}
                  />
                  <Field name="login" placeholder="Email" component={Input} />
                  <FieldError name="login" />
                  <Field
                    name="password"
                    type="password"
                    placeholder="Password"
                    component={Input}
                  />
                  <FieldError name="password" />

                  <SubmitError message={submitError} />
                  {!isAuthenticated && (
                    <div className={cx('cta-wrapper')}>
                      <button
                        className={cx('connect-button')}
                        type="submit"
                        disabled={isSubmitting || !isValid}
                      >
                        Connect
                      </button>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </LoginFormHandler>
    </div>
  );
};

export default LoginForm;
