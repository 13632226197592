import { useState } from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';

import Header from '~/components/Header';
import Footer from '~/components/Footer';
import NotOnAirPage from '~/pages/NotOnAirPage';
import { useAuthContext } from '~/context/AuthContext';

import styles from './PageContainer.module.scss';

interface IPageContainer {
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

const EVENT_DEPENDANT_ROUTES = ['/hc-quest', '/leaderboard'];

const PageContainer = ({ children }: IPageContainer): JSX.Element => {
  const history = useHistory();
  const [isEventRoute, setEventRoute] = useState<boolean>(
    EVENT_DEPENDANT_ROUTES.some((dependantRoute: string) =>
      history.location.pathname.includes(dependantRoute)
    )
  );

  const {
    isEventActive,
    isLocalAuth,
    isCountdownActive,
    isOutcomeActive,
  } = useAuthContext();

  history.listen((location: any) => {
    setEventRoute(
      EVENT_DEPENDANT_ROUTES.some((dependantRoute: string) =>
        location.pathname.includes(dependantRoute)
      )
    );
  });

  return (
    <div>
      <div
        className={cx('background', {
          event: isEventRoute && isEventActive && isLocalAuth,
          'not-on-air': isCountdownActive || isOutcomeActive,
        })}
      />
      <div className={cx('page-container')}>
        {isCountdownActive || isOutcomeActive ? (
          <NotOnAirPage />
        ) : (
          <>
            <Header />
            {children}
            <Footer
              isEvent={isEventRoute && isEventActive && isLocalAuth}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PageContainer;
