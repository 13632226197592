import classNames from 'classnames/bind';

import styles from './SubmitSuccess.module.scss';

interface ISubmitSuccess {
  message?: string;
}

const cx = classNames.bind(styles);

const SubmitSuccess = ({ message = '' }: ISubmitSuccess): JSX.Element | null => {
  return message?.length > 0 ? (
    <div className={cx('submit-success')}>{message}</div>
  ) : null;
};

export default SubmitSuccess;
