import { Formik, Form, Field } from 'formik';
import classNames from 'classnames/bind';

import Input from '~/components/FormComponents/Input';
import Select from '~/components/FormComponents/Select';
import FieldError from '~/components/FormComponents/FieldError';
import SubmitError from '~/components/FormComponents/SubmitError';
import SubmitSuccess from '~/components/FormComponents/SubmitSuccess';
import { COUNTRIES } from '~/constants/countries';
import { useAuthContext } from '~/context/AuthContext';

import EditProfileFormHandler from './containers/EditProfileFormHandler';
import styles from './EditProfileForm.module.scss';

const cx = classNames.bind(styles);

const EditProfileForm = (): JSX.Element => {
  const { isLocalAuth } = useAuthContext();

  return (
    <div className={cx('edit-profile-form')}>
      <EditProfileFormHandler>
        {({
          initialValues,
          handleEditUser,
          validationSchema,
          submitErrorMessage,
          setSubmitErrorMessage,
          submitSuccessMessage,
          setSubmitSuccessMessage,
        }) => {
          return (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleEditUser}
            >
              {(formikProps) => {
                const { isSubmitting, isValid } = formikProps;

                return (
                  <Form
                    onChange={() => {
                      setSubmitErrorMessage('');
                      setSubmitSuccessMessage('');
                    }}
                    autoComplete="off"
                  >
                    <Field
                      name="login"
                      placeholder="Email"
                      component={Input}
                      disabled
                    />
                    <FieldError name="login" />
                    {isLocalAuth && (
                      <>
                        <Field
                          name="password"
                          type="password"
                          placeholder="Password"
                          component={Input}
                        />
                        <FieldError name="password" />
                        <Field
                          name="passwordAgain"
                          type="password"
                          placeholder="Password again"
                          component={Input}
                        />
                        <FieldError name="passwordAgain" />
                      </>
                    )}
                    <Field
                      name="firstName"
                      placeholder="First name"
                      maxLength={20}
                      component={Input}
                    />
                    <FieldError name="firstName" />
                    <Field
                      name="name"
                      placeholder="Name"
                      maxLength={20}
                      component={Input}
                    />
                    <FieldError name="name" />
                    <Field
                      name="nickname"
                      placeholder="Nickname"
                      maxLength={20}
                      component={Input}
                    />
                    <FieldError name="nickname" />
                    <Field
                      name="country"
                      component={Select}
                      options={COUNTRIES}
                      placeholder="Country"
                    />
                    <FieldError name="country" />

                    <SubmitError message={submitErrorMessage} />
                    <SubmitSuccess message={submitSuccessMessage} />
                    <div className={cx('cta-wrapper')}>
                      <button
                        className={cx('submit-button')}
                        type="submit"
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          submitErrorMessage.length > 0 ||
                          submitSuccessMessage.length > 0
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </EditProfileFormHandler>
    </div>
  );
};

export default EditProfileForm;
