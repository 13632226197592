import classNames from 'classnames/bind';

import styles from './SubmitError.module.scss';

interface ISubmitError {
  message?: string;
}

const cx = classNames.bind(styles);

const SubmitError = ({ message = '' }: ISubmitError): JSX.Element | null => {
  return message?.length > 0 ? (
    <div className={cx('submit-error')}>{message}</div>
  ) : null;
};

export default SubmitError;
