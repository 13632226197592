import { gql } from '@apollo/client';

export const RECAPTCHA_VALIDATION_MUTATION = gql`
  mutation captcha($token: String!) {
    captcha(token: $token) {
      success
      score
    }
  }
`;

export const RECAPTCHA_SCORE_THRESHOLD = 0.5;
