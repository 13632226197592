import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import ContentContainer from '~/components/ContentContainer';
import LoginForm from '~/components/LoginForm';

import styles from './LoginPage.module.scss';

const cx = classNames.bind(styles);

const LoginPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ContentContainer className={cx('login-page')}>
      <div className={cx('title')}>
        <h1 className={cx('title-image')}>Login</h1>
      </div>
      <LoginForm />
      <div className={cx('bottom-links')}>
        <Link to={'/signup'} className={cx('bottom-link', 'register-link')}>
          <span>{t('login_create-account-label')}</span>
        </Link>
        <Link to={'/forgot-password'} className={cx('bottom-link', 'forget-link')}>
          <span>{t('login_forgot-pass-label')}</span>
        </Link>
      </div>
    </ContentContainer>
  );
};

export default LoginPage;
