import { useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import classNames from 'classnames/bind';
import { useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import ContentContainer from '~/components/ContentContainer';
import Loader from '~/components/Loader';
import { useAuthContext } from '~/context/AuthContext';

import styles from './LeaderbordPage.module.scss';

const cx = classNames.bind(styles);

interface IScore {
  nickname: string;
  score: string;
}
interface IScoresData {
  scores: IScore[];
}

const SCORES_QUERY = gql`
  query scores($currentEvent: Boolean) {
    scores(currentEvent: $currentEvent) {
      nickname
      score
    }
  }
`;

const LeaderbordPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { eventData, isEventActive } = useAuthContext();
  const {
    data: scoresData,
    loading: scoresLoading,
    refetch,
  } = useQuery<IScoresData>(SCORES_QUERY, {
    variables: { currentEvent: isEventActive },
  });

  useEffect(() => {
    if (scoresData) {
      refetch();
    }
  }, []);

  const goalLabels = useMemo(() => {
    if (isEventActive) {
      return eventData?.prizes.slice(0, 3);
    }

    return [t('game_goal1'), t('game_goal2'), t('game_goal3')];
  }, [eventData, isEventActive]);

  return (
    <ContentContainer className={cx('leaderboard-page')}>
      <div className={cx('left-bar')}>
        {goalLabels?.map((goalLabel, index) => (
          <div className={cx('score')} key={index}>
            {goalLabel}
          </div>
        ))}
      </div>

      <div>
        <div className={cx('leaderboard')}>
          <div className={cx('leaderboard-header')}>
            <div className={cx('rank-header')}>Rank</div>
            <div className={cx('player-header')}>Player</div>
            <div className={cx('score-header')}>Score</div>
          </div>

          {scoresLoading && <Loader className={cx('score-list-wrapper')} />}
          {scoresData?.scores && (
            <SimpleBar
              style={{ height: 527 }}
              forceVisible="y"
              autoHide={false}
            >
              <div className={cx('score-list-wrapper')}>
                <table className={cx('score-list')}>
                  <tbody>
                    {scoresData.scores.map(({ nickname, score }, index) => (
                      <tr key={index} className={cx('row-data')}>
                        <td>{index + 1}</td>
                        <td>{nickname}</td>
                        <td>{score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </SimpleBar>
          )}
        </div>

        <Link to={'/hc-quest'} className={cx('link-game')}>
          Game
        </Link>
      </div>
    </ContentContainer>
  );
};

export default LeaderbordPage;
