import classNames from 'classnames/bind';

import ContentContainer from '~/components/ContentContainer';
import EditProfileForm from '~/components/EditProfileForm';

import styles from './EditProfilePage.module.scss';

const cx = classNames.bind(styles);

const EditProfilePage = (): JSX.Element => {
  return (
    <ContentContainer className={cx('login-page')}>
      <div className={cx('title')}>
        <h1 className={cx('title-image')}>Profile modification</h1>
      </div>
      <EditProfileForm />
    </ContentContainer>
  );
};

export default EditProfilePage;
