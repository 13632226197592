import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import ContentContainer from '~/components/ContentContainer';
import ForgotPassForm from '~/components/ForgotPassForm';

import styles from './ForgotPassPage.module.scss';

const cx = classNames.bind(styles);

const ForgotPassPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ContentContainer className={cx('login-page')}>
      <div className={cx('title')}>
        <h1 className={cx('title-image')}>Login</h1>
      </div>
      <ForgotPassForm />
      <div className={cx('bottom-links')}>
        <Link to={'/login'} className={cx('bottom-link', 'login-link')}>
          <span>{t('forgotPassword_login-label')}</span>
        </Link>
      </div>
    </ContentContainer>
  );
};

export default ForgotPassPage;
