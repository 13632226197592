import { useState, useCallback, useMemo, RefObject } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  RECAPTCHA_VALIDATION_MUTATION,
  RECAPTCHA_SCORE_THRESHOLD,
} from '~/constants/recaptcha';

interface IChildrenProps {
  initialValues: any;
  handleCreateUser: (values: any, actions: any) => void;
  validationSchema: Yup.AnyObjectSchema;
  submitErrorMessage: string;
  setSubmitErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  submitSuccessMessage: string;
  setSubmitSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

interface ILoginFormHandler {
  children: (props: IChildrenProps) => JSX.Element;
  recaptchaRef: RefObject<ReCAPTCHA>;
}

const CREATE_USER_MUTATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

const initialValues = {
  login: '',
};

const ForgotPassFormHandler = ({
  children,
  recaptchaRef,
}: ILoginFormHandler): JSX.Element => {
  const { t } = useTranslation();

  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState('');

  const [validateCaptcha] = useMutation(RECAPTCHA_VALIDATION_MUTATION);
  const [updateCreateUser] = useMutation(CREATE_USER_MUTATION);

  const handleCreateUser = useCallback(async (values, { setSubmitting }) => {
    const captchaToken = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();

    validateCaptcha({
      variables: {
        token: captchaToken,
      },
    }).then(({ data }: any) => {
      if (data?.captcha.success && data.captcha.score >= RECAPTCHA_SCORE_THRESHOLD) {
        updateCreateUser({
          variables: {
            email: values.login,
          },
        })
          .then(() => {
            setSubmitting(false);
            setSubmitSuccessMessage(t('forgotPassword_submit-success'));
    
            console.log('Email submitted.');
          })
          .catch((error) => {
            console.warn(error);
            setSubmitting(false);
            setSubmitErrorMessage(error);
          });
      } else {
        setSubmitting(false);
        setSubmitErrorMessage(t('generic_error'));
      }
    });
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        login: Yup.string()
          .email(t('form_email-validation'))
          .required(t('form_required-field')),
      }),
    [t]
  );

  return children({
    initialValues,
    handleCreateUser,
    validationSchema,
    submitErrorMessage,
    setSubmitErrorMessage,
    submitSuccessMessage,
    setSubmitSuccessMessage,
  });
};

export default ForgotPassFormHandler;
