import classNames from 'classnames/bind';

import styles from './ContentContainer.module.scss';

interface IContentContainer {
  className?: string;
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

const ContentContainer = ({
  className,
  children,
}: IContentContainer): JSX.Element => {
  return <main className={cx('content-container', className)}>{children}</main>;
};

export default ContentContainer;
