import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import InfoPage from '~/pages/InfoPage';
import { LS_LOGIN_AFTER_CREATE } from '~/constants/localStorage';

const FirstLoginInfoPage = (): JSX.Element => {
  const { t } = useTranslation();
  localStorage.removeItem(LS_LOGIN_AFTER_CREATE);

  const text = t('infoPage_first-login-message');

  if (text.includes('@firstLoginLinkLabel')) {
    const [fromText, perText] = t('infoPage_first-login-message').split(
      '@firstLoginLinkLabel'
    );

    return (
      <InfoPage>
        <span dangerouslySetInnerHTML={{ __html: fromText }} />
        <Link to={'/hc-quest'}>
          <span>{t('infoPage_first-login-link-label')}</span>
        </Link>
        <span dangerouslySetInnerHTML={{ __html: perText }} />
      </InfoPage>
    );
  }

  return <InfoPage text={text} />;
};

export default FirstLoginInfoPage;
